import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import css from './HubspotForm.module.css';

const HubspotForm = (props) => {
  const { t, i18n } = useTranslation('signup');
  const lang = i18n.language;

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${i18n.language}/step1`,
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '3985867',
          formId:
            lang === 'fr'
              ? 'b41ba0f9-d041-4b86-ae2e-c763b0b20b5d'
              : '3a7da876-450a-4c06-bf5a-6854aa5d9987',
          target: '#hubspotForm',
        });
      }
    });
  }, [lang]);

  return (
    <>
      <div className={css.Account}>
        <h1>{t('hubspotForm.title', 'Sign Up')}</h1>
        {/* <p>
          {t('hubspotForm.noCredit', "Please fill out this form and we'll contact you quickly")}
        </p> */}
        <div>
          <div id="hubspotForm"></div>
        </div>
      </div>
    </>
  );
};

export default HubspotForm;
