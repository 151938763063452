import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import css from './CarrierBox.module.css';

import ConnectStep from './ConnectStep/ConnectStep';

import x from '../../../../../assets/images/x.svg';
import doneSign from '../../../../../assets/images/done-sign.svg';

const carrierBox = (props) => {
  const { t } = useTranslation('signup');
  const [loading, setLoading] = useState(false);

  let content = '';
  if (props.carrier.step === 'connect' || props.carrier.step === 'connecting') {
    content = <ConnectStep carrier={props.carrier} loading={loading} setLoading={setLoading} />;
  }

  let bottom = null;
  if (props.carrier.step === 'done') {
    bottom = (
      <div className={css.BottomDone}>
        <div>
          <img src={doneSign} alt="Done" />
        </div>
        <div>{t('carriers.box.connected', 'Connected successfully')}</div>
      </div>
    );
  }

  return (
    <div className={css.CarrierBox}>
      <div className={css.Title}>
        <div>{props.carrier.title}</div>
        {!loading && props.carrier.step === 'connect' ? (
          <button className={css.RemoveButton} onClick={props.remove}>
            <img src={x} alt={t('common.remove')} />
          </button>
        ) : null}
      </div>
      {content}
      {bottom}
    </div>
  );
};

export default carrierBox;
