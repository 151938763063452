import React, { useState } from 'react';
import {
  // CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  // PaymentRequestButtonElement,
  // IbanElement,
  // IdealBankElement,
  // StripeProvider,
  // Elements
} from 'react-stripe-elements';

import css from './StripeInput.module.css';

const stripeInput = (props) => {
  const [hasFocus, setFocus] = useState(false);
  // const [isReady, setReady] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [error, setError] = useState(false);

  const tags = {
    CardNumberElement: CardNumberElement,
    CardExpiryElement: CardExpiryElement,
    CardCVCElement: CardCVCElement,
    PostalCodeElement: PostalCodeElement,
  };

  const TagName = tags[props.type];

  const options = {
    style: {
      base: {
        fontFamily: `'Open Sans', sans-serif`,
        fontWeight: '400',
        fontSize: '18.67px',
        color: '#404041',
        '::placeholder': {
          color: 'white',
        },
      },
      invalid: {
        color: 'red',
      },
    },
  };

  if (hasFocus || !empty) {
    options.style.base['::placeholder'].color = '#ddd';
  }

  return (
    <div
      className={[
        css.StripeInput,
        hasFocus || !empty ? css.HasFocus : null,
        error ? css.Error : null,
        props.half ? css.Half : null,
      ].join(' ')}
    >
      <label>{props.placeholder}</label>

      <TagName
        onBlur={() => {
          setFocus(false);
        }}
        onFocus={() => {
          setFocus(true);
        }}
        // onReady={() => { setReady(true); }}
        onChange={(change) => {
          setEmpty(change.empty);
          setError(change.error);
        }}
        {...options}
      />
    </div>
  );
};

export default stripeInput;
