import React from 'react';

import css from './Loader.module.css';

const loader = (props) => {
  return (
    <svg width="70" height="20" className={css.Loader}>
      <circle cx="10" cy="10" r="0">
        <animate
          attributeName="r"
          from="0"
          to="10"
          values="0;10;10;10;0"
          dur="1000ms"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="35" cy="10" r="0">
        <animate
          attributeName="r"
          from="0"
          to="10"
          values="0;10;10;10;0"
          begin="200ms"
          dur="1000ms"
          repeatCount="indefinite"
        />
      </circle>
      <circle cx="60" cy="10" r="0">
        <animate
          attributeName="r"
          from="0"
          to="10"
          values="0;10;10;10;0"
          begin="400ms"
          dur="1000ms"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default loader;
