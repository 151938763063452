import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';

import Signup from './containers/Signup/Signup';

const App = () => {
  let dev =
    window.location.href.indexOf('dev') > 0 || window.location.href.indexOf('localhost') > 0;
  let apiKey = dev ? 'pk_test_BGp9yxuVTgdHVHoj2CSLziAM' : 'pk_live_jnlaJsF5lRrYJbgwtVE5UVNG';

  return (
    <BrowserRouter>
      <div className="App">
        <StripeProvider apiKey={apiKey}>
          <Switch>
            <Route path="/:locale/:path" component={Signup} />
            <Route path="/:locale" component={Signup} />
            <Redirect to="/en" />
          </Switch>
        </StripeProvider>
      </div>
    </BrowserRouter>
  );
};

export default App;
