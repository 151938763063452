import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import css from './Confirmation.module.css';
import Button from '../../../UI/Button/Button';

const confirmation = (props) => {
  const { t, i18n } = useTranslation('signup');

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${i18n.language}/step4`,
    });
  }, []);

  let dev =
    window.location.href.indexOf('dev') > 0 || window.location.href.indexOf('localhost') > 0;
  let loginUrl = dev ? 'http://clients.busterfetcher.local' : 'https://clients.busterfetcher.com';

  if (i18n.language === 'fr') {
    loginUrl = dev
      ? 'http://clients.busterfetcher.local/login/fr'
      : 'https://clients.busterfetcher.com/login/fr';
  }

  return (
    <div className={css.Confirmation}>
      <h1>{t('confirmation.title', 'Welcome to Buster Fetcher')}</h1>
      <p>
        {t('confirmation.happy', 'We are happy to have you onboard!')}
        <br />
        <br />

        {t('confirmation.visit', 'Visit your dashboard to view all transactions.')}
        <br />
        <br />

        {t('confirmation.next', 'Short brief on what happens next.')}
      </p>
      <div className={css.Next}>
        <Button onClick={() => (window.location = loginUrl)}>
          {t('confirmation.submit', 'Visit your dashboard')}
        </Button>
      </div>
    </div>
  );
};

export default confirmation;
