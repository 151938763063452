import React from 'react';

import css from './ErrorMessage.module.css';
import warnSign from '../../../assets/images/warn-sign.svg';

const errorMessage = (props) => {
  return (
    <div className={css.ErrorMessage} id={props.id} name={props.name}>
      <div>
        <img src={warnSign} alt="Alert" />
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default errorMessage;
