import React, { useState } from 'react';
import parse from 'html-react-parser';

import css from './Input.module.css';

const Input = (props) => {
  const [hasFocus, setFocus] = useState(false);
  const [value, setValue] = useState(props.value);
  if (props.type === 'checkbox') {
    return (
      <div
        className={[css.Input, props.error ? css.Error : null, props.half ? css.Half : null].join(
          ' '
        )}
      >
        <input
          {...props}
          half={null}
          error={null}
          placeholder={null}
          onChange={(event) => {
            setValue(event.target.checked);
            props.onChange && props.onChange(event);
          }}
        />
        <span>{parse(props.placeholder || '')}</span>
      </div>
    );
  } else if (props.type === 'radio') {
    return (
      <div
        className={[css.Input, props.error ? css.Error : null, props.half ? css.Half : null].join(
          ' '
        )}
      >
        <input
          {...props}
          half={null}
          error={null}
          placeholder={null}
          onChange={(event) => {
            setValue(event.target.checked);
            props.onChange && props.onChange(event);
          }}
        />
        <span>{parse(props.placeholder || '')}</span>
      </div>
    );
  } else {
    return (
      <div
        className={[
          css.Input,
          css.MovingPlaceholder,
          hasFocus || value ? css.HasFocus : null,
          props.error ? css.Error : null,
          props.half ? css.Half : null,
        ].join(' ')}
      >
        <span>{props.placeholder}</span>
        <input
          {...props}
          half={null}
          error={null}
          placeholder={null}
          onFocus={() => {
            setFocus(true);
          }}
          onBlur={() => {
            setFocus(false);
          }}
          onChange={(event) => {
            setValue(event.target.value);
            props.onChange && props.onChange(event);
          }}
        />
      </div>
    );
  }
};

export default Input;
