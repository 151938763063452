import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Elements, injectStripe } from 'react-stripe-elements';

import css from './CompanyInfo.module.css';
import axios from '../../../../axios-bf';
import * as actionTypes from '../../../../store/actions';

import Form from '../../../UI/Form/Form';
import Input from '../../../UI/Input/Input';
import Button from '../../../UI/Button/Button';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';
import StripeInput from './StripeInput/StripeInput';

const NonStripeInjectedCompanyInfo = (props) => {
  const { t, i18n } = useTranslation('signup');
  const [info, setInfo] = useState({});
  const [error, setError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [billingContactError, setBillingContactError] = useState('');
  const [billingInfoError, setBillingInfoError] = useState('');
  const [errorFields, setErrorFields] = useState({});

  useEffect(() => {
    // Push event to Google Analytics
    window.dataLayer.push({
      event: 'virtual_pageview',
      virtualPageURL: `/virtual/${i18n.language}/step3`,
    });
  }, []);

  const onChange = (event) => {
    setInfo({
      ...info,
      [event.target.name]: event.target.value,
    });
    setErrorFields({
      ...errorFields,
      [event.target.name]: false,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validation
    const companyErrors = [];
    const billingContactErrors = [];
    const billingInfoErrors = [];
    const fields = [];
    const validEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let stripeToken = null;

    if (!info.address || !info.address.trim()) {
      companyErrors.push(t('common.streetAddress', 'Street Address'));
      fields.push('address');
    }
    if (!info.city || !info.city.trim()) {
      companyErrors.push(t('common.city', 'City'));
      fields.push('city');
    }
    if (!info.province || !info.province.trim()) {
      companyErrors.push(t('common.province', 'Province'));
      fields.push('province');
    }
    if (!info.country || !info.country.trim()) {
      companyErrors.push(t('common.country', 'Country'));
      fields.push('country');
    }
    if (!info.postalCode || !info.postalCode.trim()) {
      companyErrors.push(t('common.postalCode', 'Postal Code'));
      fields.push('postalCode');
    }

    if (!info.company || !info.company.trim()) {
      billingContactErrors.push(t('common.companyName', 'Company Name'));
      fields.push('company');
    }
    if (!info.firstName || !info.firstName.trim()) {
      billingContactErrors.push(t('common.contactFirstName', 'Contact First Name'));
      fields.push('firstName');
    }
    if (!info.lastName || !info.lastName.trim()) {
      billingContactErrors.push(t('common.lastName', 'Last Name'));
      fields.push('lastName');
    }
    if (!info.email || !validEmail.test(info.email.trim())) {
      billingContactErrors.push(t('common.email', 'Email'));
      fields.push('email');
    }
    if (!info.phone || !info.phone.trim()) {
      billingContactErrors.push(t('common.phone', 'Phone'));
      fields.push('phone');
    }

    // Create Stripe Token
    if (props.stripe) {
      const res = await props.stripe.createToken();
      console.log('createToken', res);
      // Success
      if (res.token) {
        stripeToken = res.token.id;
      }
      // Error
      else {
        billingInfoErrors.push(res.error.message);
      }
    } else {
      // Stripe.js hasn't loaded yet
      billingInfoErrors.push(
        t('common.unexpectedError', 'We are sorry but an unexpected error occured.')
      );
    }

    if (companyErrors.length) {
      let messageStart = t('common.validField', 'Please enter a valid') + ' ';
      if (companyErrors.length > 1)
        messageStart = t('common.validFields', 'Please enter valid:') + ' ';
      setCompanyError(messageStart + companyErrors.join(', ') + '.');
    } else {
      setCompanyError('');
    }

    if (billingContactErrors.length) {
      let messageStart = t('common.validField', 'Please enter a valid') + ' ';
      if (billingContactErrors.length > 1)
        messageStart = t('common.validFields', 'Please enter valid:') + ' ';
      setBillingContactError(messageStart + billingContactErrors.join(', ') + '.');
    } else {
      setBillingContactError('');
    }

    if (billingInfoErrors.length) {
      setBillingInfoError(billingInfoErrors.join(' '));
    } else {
      setBillingInfoError('');
    }

    if (companyErrors.length || billingContactErrors.length || billingInfoErrors.length) {
      setErrorFields(Object.assign({ errorFields }, ...fields.map((field) => ({ [field]: true }))));
      return;
    }

    // Send request to API
    const params = {
      token: props.token,
      subscriptionType: 'customer',
      street: info.address,
      // street2: '',
      city: info.city,
      state: info.province,
      country: info.country,
      postalCode: info.postalCode,
      business: info.company,
      stripeToken: stripeToken,
    };

    axios
      .post('/subscribe/billing', params)
      .then((res) => {
        console.log(res);
        // Handle error message
        if (res.data && res.data.error) {
          setError(res.data.error);
        }
        // Handle success
        else if (res.data && res.data.saved) {
          props.setCurrentStep('confirmation');
        }
        // Unexpected error
        else {
          setError(t('common.unexpectedError', 'We are sorry but an unexpected error occured.'));
        }
      })
      .catch((error) => {
        // Connexion error
        setError(error);
      });
  };

  // Handle error messages
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  let companyErrorMessage = null;
  if (companyError) {
    companyErrorMessage = <ErrorMessage>{companyError}</ErrorMessage>;
  }

  let billingContactErrorMessage = null;
  if (billingContactError) {
    billingContactErrorMessage = <ErrorMessage>{billingContactError}</ErrorMessage>;
  }

  let billingInfoErrorMessage = null;
  if (billingInfoError) {
    billingInfoErrorMessage = <ErrorMessage>{billingInfoError}</ErrorMessage>;
  }

  return (
    <div className={css.CompanyInfo}>
      <h1>{t('companyInfo.title')}</h1>
      {/* <p>{t('companyInfo.description')}</p> */}

      <form onSubmit={onSubmit}>
        <div className={css.Forms}>
          {errorMessage}

          <Form title={t('claimInfo.companyInfo', 'Company Info')}>
            {companyErrorMessage}
            <Input
              name="address"
              placeholder={t('common.streetAddress', 'Street Address')}
              onChange={onChange}
              error={errorFields.address}
            />
            <Input
              name="city"
              placeholder={t('common.city', 'City')}
              onChange={onChange}
              error={errorFields.city}
            />
            <Input
              name="province"
              placeholder={t('common.province', 'Province')}
              onChange={onChange}
              error={errorFields.province}
            />
            <Input
              name="country"
              placeholder={t('common.country', 'Country')}
              onChange={onChange}
              error={errorFields.country}
            />
            <Input
              name="postalCode"
              placeholder={t('common.postalCode', 'Postal Code')}
              onChange={onChange}
              error={errorFields.postalCode}
            />
          </Form>

          <hr />

          <Form title={t('claimInfo.billingContact', 'Billing Contact')}>
            {billingContactErrorMessage}
            <Input
              name="company"
              placeholder={t('common.companyName', 'Company Name')}
              onChange={onChange}
              error={errorFields.company}
            />
            <Input
              name="firstName"
              placeholder={t('common.contactFirstName', 'Contact First Name')}
              half
              onChange={onChange}
              error={errorFields.firstName}
            />
            <Input
              name="lastName"
              placeholder={t('common.lastName', 'Last Name')}
              half
              onChange={onChange}
              error={errorFields.lastName}
            />
            <Input
              name="email"
              placeholder={t('common.email', 'Email')}
              onChange={onChange}
              error={errorFields.email}
            />
            <Input
              name="phone"
              placeholder={t('common.phone', 'Phone')}
              onChange={onChange}
              error={errorFields.phone}
            />
          </Form>

          <hr />

          <Form
            title={t('claimInfo.billingInformation', 'Billing Information')}
            description={t(
              'claimInfo.billingInformationDesc',
              'Your billing info will not be saved on any of our servers. Billing is processed and secured by Stripe.'
            )}
          >
            {billingInfoErrorMessage}
            <StripeInput
              placeholder={t('common.cardNumber', 'Card Number')}
              type="CardNumberElement"
            />
            <StripeInput
              placeholder={t('common.expiryDate', 'Expiry Date')}
              type="CardExpiryElement"
              half
            />
            <StripeInput placeholder={t('common.cvv', 'CVV')} type="CardCVCElement" half />
            <StripeInput
              placeholder={t('common.postalCode', 'Postal Code')}
              type="PostalCodeElement"
              half
            />
          </Form>

          <div className={css.Next}>
            <Button>{t('companyInfo.submit')}</Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentStep: (step) => dispatch({ type: actionTypes.SET_CURRENT_STEP, step: step }),
  };
};

// Inject Stripe Elements
const StripeInjectedCompanyInfo = injectStripe(NonStripeInjectedCompanyInfo);
const CompanyInfo = (props) => (
  <Elements>
    <StripeInjectedCompanyInfo {...props} />
  </Elements>
);

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
