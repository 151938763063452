import React from 'react';
import {useTranslation} from 'react-i18next';

import css from './Faq.module.css';

const faq = (props) => {
  const {t} = useTranslation('signup');

  return (
    <div className={css.Faq}>
      <h3>{t('infoPanel.faq.title', 'FAQ')}</h3>
      {props.children}
    </div>
  );
}

export default faq;
