import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import css from './ConnectStep.module.css';
import * as actionTypes from '../../../../../../store/actions';
import axios from '../../../../../../axios-bf';

import Form from '../../../../../UI/Form/Form';
import Input from '../../../../../UI/Input/Input';
import Button from '../../../../../UI/Button/Button';
import ErrorMessage from '../../../../../UI/ErrorMessage/ErrorMessage';
import loadingGif from '../../../../../../assets/images/loading.gif';

const connectStep = (props) => {
  const { t } = useTranslation('signup');
  // const [data, setData] = useState({
  //   username: 'homeoanimal',
  //   pwd: '!Ancrage3422',
  //   validating_credentials: true,
  //   token: '1cb83fe7ce50afa0dca44947348462a6c90a74d1',
  //   message: 'i18n::subscribe.canadapost.select_accounts',
  //   fields: [
  //     {
  //       name: 'email',
  //       label: 'i18n::subscribe.purolator.username',
  //       required: 'You must fill this field',
  //     },
  //     {
  //       name: 'password',
  //       label: 'i18n::subscribe.purolator.password',
  //       type: 'password',
  //       required: 'You must fill this field',
  //     },
  //     {
  //       label: 'Please check the checkbox',
  //       required: 'You must check the checkbox',
  //       name: 'checkbox_test',
  //       type: 'checkbox',
  //       value: 'I accept',
  //     },
  //     {
  //       label: 'i18n::subscribe.canadapost.accounts',
  //       required: 'i18n::subscribe.canadapost.required_account',
  //       name: 'user_feedback',
  //       type: 'multi_checkbox',
  //       options: [
  //         {
  //           label: 'GROUPE CYRENNE INC (0008097920) ',
  //           value: '0008097920',
  //           disabled: false,
  //         },
  //         {
  //           label: 'WOIN (123456) ',
  //           value: '123456',
  //           disabled: true,
  //         },
  //         {
  //           label: 'GROUPE COOL (789123) ',
  //           value: '789123',
  //           disabled: false,
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Test de radios',
  //       required: 'Il faut sélectionner un radio',
  //       name: 'radio_option',
  //       type: 'radio',
  //       options: [
  //         {
  //           label: 'GROUPE CYRENNE INC (0008097920) ',
  //           value: '0008097920',
  //           disabled: false,
  //         },
  //       ],
  //     },
  //   ],
  // });
  const [data, setData] = useState({});
  const [first, setFirst] = useState(true);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const { loading, setLoading } = props;

  useEffect(() => {
    connect();
  }, []);

  const connect = () => {
    // Disable remove button
    props.setCarrierStep('connecting');

    // Disable find button & remove error message
    setLoading(true);
    setError(null);

    // Format data
    const formattedData = {};
    Object.entries(data).forEach(([k, v]) => {
      if (k.endsWith('__array')) {
        const key = k.substring(0, k.indexOf('__'));
        if (!Array.isArray(formattedData[key])) formattedData[key] = [];
        formattedData[key].push(v);
      } else {
        formattedData[k] = v;
      }
    });

    // Validation
    const newErrors = {};
    Array.isArray(data.fields) &&
      data.fields
        .filter((f) => f.required)
        .forEach((f) => {
          if (f.type === 'multi_checkbox') {
            const one = f.options.some(
              (o) => formattedData[f.name] && formattedData[f.name].includes(o.value)
            );
            if (!one) newErrors[f.name] = true;
          } else {
            if (!formattedData[f.name]) newErrors[f.name] = true;
          }
        });

    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      setLoading(false);
      return;
    } else {
      setErrors({});
    }

    // Send connect API request
    const params = { token: props.token, ...formattedData };
    if (first) {
      params.new = true;
      setFirst(false);
    }
    axios
      .post(`/subscribe/carriers/${props.carrier.name}/connect`, params)
      .then((res) => {
        setLoading(false);
        // Handle success
        if (res && res.data && res.data.complete && res.data.account) {
          props.setCarrierId(props.carrier.key, res.data.account);
          props.setCarrierStep(props.carrier.key, 'done');
        }
        // Handle message & requested fields
        else if (res && res.data && res.data.fields) {
          setData(res.data);
        }
        // Unexpected error
        else {
          setError(t('common.unexpectedError', 'We are sorry but an unexpected error occured.'));
          props.setCarrierStep('connect');
        }
      })
      .catch((error) => {
        setLoading(false);
        // Connexion error
        setError(error.message);
      });
  };

  const parseI18n = (text) => {
    text = typeof text === 'string' ? text : '';
    const i18nStrings = text.match(/(i18n::[^\n '"]*)/g);
    if (i18nStrings) {
      i18nStrings.forEach((s) => {
        text = text.replace(s, t(s.substr(6)));
      });
    }
    return text;
  };

  const generateForm = (data = {}) => {
    const newForm = [];

    if (data.message) {
      newForm.push(<p key="message">{parseI18n(data.message)}</p>);
    }

    if (data.error) {
      newForm.push(<ErrorMessage key="error">{parseI18n(data.error)}</ErrorMessage>);
    }

    if (Array.isArray(data.fields)) {
      data.fields.forEach(function (field, i) {
        if (field.type === 'checkbox') {
          if (field.required) {
            newForm.push(
              <div
                key={field.name + '_required'}
                className={[css.textDanger, css.small, errors[field.name] ? '' : css.hidden].join(
                  ' '
                )}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <Input
              key={field.name}
              name={field.name}
              value={field.value}
              type="checkbox"
              placeholder={parseI18n(field.label)}
              required={!!field.required}
              disabled={!!field.disabled}
              onChange={onChange}
            />
          );
        } else if (field.type === 'multi_checkbox') {
          if (field.label) {
            newForm.push(<p key={field.name + '_label'}>{parseI18n(field.label)}</p>);
          }
          if (field.required) {
            newForm.push(
              <div
                key={field.name + '_required'}
                className={[css.textDanger, css.small, errors[field.name] ? '' : css.hidden].join(
                  ' '
                )}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <div key={field.name + '_checkboxes'}>
              {field.options.map((option, j) => {
                return (
                  <div key={field.name + '_checkbox_' + j} className="checkbox check-primary">
                    <Input
                      name={field.name + '__' + j + '__array'}
                      value={option.value}
                      type="checkbox"
                      placeholder={parseI18n(option.label)}
                      required={!!field.required}
                      disabled={!!option.disabled}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
            </div>
          );
        } else if (field.type === 'radio') {
          if (field.label) {
            newForm.push(<p key={field.name + '_label'}>{parseI18n(field.label)}</p>);
          }
          if (field.required) {
            newForm.push(
              <div
                key={field.name + '_required'}
                className={[css.textDanger, css.small, errors[field.name] ? '' : css.hidden].join(
                  ' '
                )}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          newForm.push(
            <div key={field.name + '_radios'}>
              {field.options.map((option, j) => {
                return (
                  <div key={field.name + '_radio_' + j} className="checkbox check-primary">
                    <Input
                      name={field.name}
                      value={option.value}
                      type="radio"
                      placeholder={parseI18n(option.label)}
                      required={!!field.required}
                      disabled={!!option.disabled}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
            </div>
          );
        } else if (field.type === 'hidden') {
          newForm.append(
            <input key={field.name} type="hidden" name={field.name} value={field.default} />
          );
        } else {
          if (field.required) {
            newForm.push(
              <div
                key={field.name + '_required'}
                className={[css.textDanger, css.small, errors[field.name] ? '' : css.hidden].join(
                  ' '
                )}
              >
                {parseI18n(field.required)}
              </div>
            );
          }
          if (field.type === 'password') {
            newForm.push(
              <Input
                key={field.name}
                name={field.name}
                type="password"
                placeholder={parseI18n(field.label)}
                autoComplete="new-password"
                onChange={onChange}
              />
            );
          } else {
            newForm.push(
              <Input
                key={field.name}
                name={field.name}
                type="text"
                placeholder={parseI18n(field.label)}
                autoComplete="off"
                onChange={onChange}
              />
            );
          }
        }
      });
    }

    return newForm;
  };

  // console.log('data', data);
  const onChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === 'checkbox'
        ? event.target.checked && event.target.value
        : event.target.value;
    setData({ ...data, [name]: value });
  };

  // Error message
  let errorMessage = null;
  if (error) {
    errorMessage = <ErrorMessage>{error}</ErrorMessage>;
  }

  return (
    <div className={css.ConnectStep}>
      <Form>
        {errorMessage}
        {generateForm(data)}
      </Form>

      {loading ? (
        <div style={{ marginTop: '20px', display: 'flex' }}>
          <img src={loadingGif} alt="..." style={{ width: 38, height: 38, marginRight: '20px' }} />
          <div>{t('subscribe.common.loading')}</div>
        </div>
      ) : (
        <div className={css.Button}>
          <Button onClick={() => connect()} disabled={loading}>
            {t('carriers.box.connect', 'Connect')}
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCarrierStep: (key, step) =>
      dispatch({ type: actionTypes.SET_CARRIER_STEP, key: key, step: step }),
    setCarrierId: (key, id) => dispatch({ type: actionTypes.SET_CARRIER_ID, key: key, id: id }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(connectStep);
